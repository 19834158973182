

export default function Footer() {
  return (
    <footer id="footer">
      <img src="https://horizonsend.com/images/horizons-end_logo-small.png" alt="Horizon's End" />
      <p>
        <a href="https://www.facebook.com/horizonsendgaming" target="_blank" rel="noreferrer" title="Horizon's End Facebook page">
          <i className="fab fa-facebook-square"></i>&nbsp;
          <span>Facebook</span>
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://www.instagram.com/horizonsendgaming/" target="_blank" rel="noreferrer"  title="Horizon's End on Instagram">
          <i className="fab fa-instagram"></i>&nbsp;
          <span>Instagram</span>
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://twitter.com/horizonsendinc" target="_blank" rel="noreferrer"  title="Horizon's End on Twitter">
          <i className="fab fa-twitter"></i>&nbsp;
          <span>Twitter</span>
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://discord.gg/tGy577j" target="_blank" rel="noreferrer"  title="Horizon's End on Discord">
          <i className="fab fa-discord"></i>&nbsp;
          <span>Discord</span>
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://www.youtube.com/user/horizonsendgaming" target="_blank" rel="noreferrer"  title="Horizon's End YouTube channel">
          <i className="fab fa-youtube"></i>&nbsp;
          <span>YouTube</span>
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
{/*
       <a href="https://thegreatgaias.gamepedia.com/The_Great_Gaias_Wiki" target="_blank" rel="noreferrer"  title="The Great Gaias Official Wiki hosted by Curse">
          <i className="fas fa-edit"></i>&nbsp;
          <span>Wiki</span>
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
*/}
        <a href="mailto:horizonsendgaming@gmail.com" title="Send an email to Horizon's End">
          <i className="fas fa-envelope"></i>&nbsp;
          <span>Contact</span>
        </a>
      </p>
      <small>&copy; {new Date().getFullYear()} Horizon's End, Inc. All Rights Reserved.</small>
    </footer>
  )
}

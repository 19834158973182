import { useState } from 'react'
import server from '../utilities/server'
import { config } from '../config'

export default function ChangeTempPasswordForm({ api }) {
  const [state, setState] = useState({ errors: '' })

  const changeTempPassword = async event => {
    event.preventDefault()
    if (event.target.elements[2].value !== event.target.elements[3].value) {
      return setState({ errors: 'Passwords do not match.' })
    }
    const store = JSON.parse(sessionStorage.getItem('store'))
    const request = {
      username: event.target.elements[1].value,
      new_password: event.target.elements[2].value,
      challenge_name: store.message.ChallengeName,
      session: store.message.Session
    }
    const response = await server.auth(api, request)
    if (response.error !== undefined) {
      return setState({ errors: response.error.message })
    }
    sessionStorage.removeItem('store')
    sessionStorage.setItem('AccessToken', response.message.AuthenticationResult.AccessToken)
    sessionStorage.setItem('IdToken', response.message.AuthenticationResult.IdToken)
    window.location.href = config.routes.admin.create_news
  }

  return (
    <form className="change-temp-password-form" onSubmit={changeTempPassword}>
     {state.errors ? <div className="errors">{state.errors}</div> : <></>}
      <fieldset>
        <legend>Reset temporary password</legend>
        <div>
          <input type="text" placeholder="Username" required />
        </div>
        <div>
          <input type="password" placeholder="New password" minLength="18" required />
        </div>
        <div>
          <input type="password" placeholder="Confirm new password" required />
        </div>
      </fieldset>
      <div>
        <button type="submit">Login</button>
      </div>
    </form>
  )
}

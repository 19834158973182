import { useState, useEffect } from 'react'
import server from './utilities/server'
import { config } from './config'

export default function Protected({ children }) {
  const [state, setState] = useState({ is_authenticated: false })

  const validateSession = async () => {
    const token = sessionStorage.getItem('token')
    if (token === null) {
      return window.location.href = config.routes.login
    }
    const id = sessionStorage.getItem('SessionId')
    const response = await server.validateAuth(config.api.validate_session, { id, token })
    if (response.error !== undefined) {
      // return errorLog(response.error.message)
      return alert(response.error.message)
    }
    if (!response.message.valid) {
      // this should load a 404 page ...returned by this component?
      alert('Invalid session.')
      return window.location.href = config.routes.home
    }
    setState({ is_authenticated: true })  
  }
  
  useEffect(() => {
    const asyncWrapper = async () => {
      validateSession()
    }
    asyncWrapper()
  }, [])

  return (
    <div>{state.is_authenticated ? children : <></>}</div>
  )
}

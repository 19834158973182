import { useState, useEffect } from 'react'

export default function LoadingOverlay() {
  const [state, setState] = useState({ ellipsis: '' })
  
  const createEllipsis = async () => {
    if (state.ellipsis.length >= 6) {
      await new Promise(resolve => setTimeout(resolve, 1000))
      return setState({ ellipsis: '' })
    }
    await new Promise(resolve => setTimeout(resolve, 1000))
    setState({ ellipsis: state.ellipsis + ' .' })
  }
  
  useEffect(() => {
    createEllipsis()
  }, [state.ellipsis])
  
  return (
    <div className="loading-overlay">
      <div>
        <i className="fas fa-cog fa-spin"></i>
        <span>Loading{state.ellipsis}</span>
      </div>
    </div>
  )
}

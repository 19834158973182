import { config } from '../../config'
import server from '../../utilities/server'

export default function AdminForgotPasswordForm() {
  const resetPassword = async event => {
    event.preventDefault()
    const request = {
      username: event.target.elements[0].value
    }
    const response = await server.auth(config.api.admin_forgot_password, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    window.location.href = config.routes.admin.recover_password
  }
  
  return (
    <section className="form-background">
      <form className="user-form buffer-space" onSubmit={resetPassword}>
        <div>
          <h4>Forgot your password?</h4>
        </div>
        <div>
          <label htmlFor="username">Username</label>
          <input id="username" type="text" placeholder="Username" required />
        </div>
        <div>
          <span>Be sure to check your email for the recovery code.</span>
        </div>
        <div>
          <button type="submit">Recover Password</button>
        </div>
      </form>
    </section>  
  )
}
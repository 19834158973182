import { createContext, useReducer } from 'react'

export const Context = createContext()

export default function Provider({ children }) {

  const data = {
    player: undefined,
    modal: { 
      type: undefined, 
      show: false, 
      options: undefined 
    }
  }

  function reducer(state, action) {
    switch (action.type) {
      case 'modal':
        return { ...state, modal: { 
            type: action.payload, 
            show: !state.modal.show,
            options: action.options
          } 
        }
      case 'youtube':
        return { ...state, player: action.payload }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, data)
  
  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
}


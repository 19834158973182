export const tgg_data = [
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-atrius.jpg",
    title: "Atrius Althorand",
    description: "Atrius is the last blood of the respected and affluent House Althorand. The course of his life changed dramatically during his childhood when his parents were killed by a destitute vagrant at the end of his rope. Since then Atrius has taken care of his younger sister Elaenia. Atrius trained himself and eventually became a master of the art of thievery and assassination. Ultimately he founded the Orgard Thieves Guild where he became Pots' employer and closest confidant."
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-agramed.jpg",
    title: "Agramed Oslo",
    description: "Agramed Oslo was one of the greatest General's that the Osirian Empire had ever seen. He was condemned as a traitor after being framed for the attempted murder of Cheiftain Moreth, he became the Osirian Gladiator Champion for four years.'"
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-furi.jpg",
    title: "Furi Stormcrow",
    description: "Furi Stormcrow is the Keeper of Magic's daughter. It is said that she is a natural when dealing with magic. She is the same age as Pots, who's father is also on the council, so they were familiar while growing up. Furi has a reputation for being ostentatious and cocksure. She is proud and feels her time shouldn't be wasted on those without magical talent.'"
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-griswold.jpg",
    title: "Griswold Stormprey",
    description: "Griswold Stormprey has been a practitiouner of the arts of healing magic for over fifty years, and has achieved such titles as Master of the Adept and Keeper of Storms. He is one of the highest ranks within the Circle of Twelve, the High Order of Magi. Upon receiving a premonition, Griswold came to Orgard to save the Children of Light. Griswold believes he is the reborn soul of Arman Gill, Cleric of Tantalasia."
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-virgil.jpg",
    title: "Virgil Dal'Laman Mythos",
    description: "Virgil Dal'Laman Mythos was born in a small village south of the Solisteri Forest. Never being considered strong or very handsome, he began his quiet study of magic. Over the years Virgil became quite adept at sorcery, to the horror of his village. After a great tragedy, Virgil began his quest to learn great magic."
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-elric.jpg",
    title: "Elric Yore",
    description: "Elric Yore hails from Sovereign, the city of knights. His father was the Keeper of the Sword before he succumbed to fever. Elric has since left from his long held place as a Knight of Sovereign to follow his own path. He is most skillful in the tracking and hunting of beasts in the wild, and having giving up his title and land after his Father's death, he has taken on the role of Ranger."
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-eldromus.jpg",
    title: "Eldromus Blackheart",
    description: "Born Eldromus Cortlabar of the land of Validus. Eldromus grew up with Arthur Greyhem, and they both became Generals in the Validian Empire. The two became renowned for their displays of loyalty and honor toward the Empire, and each other. However, when Aman Gill finally convinced Arthur Greyhem to rebel against Validus, Eldromus turned against Arthur and the Silver Army, eaning himself the heinous sobriquet, The Betrayer. The Elven Lorac Cael Vaeili cursed Eldromus causing him to walk the land forever until 'justice is done'."
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-bruger.jpg",
    title: "Bruger Ironhelm",
    description: "Bruger Ironhelm is a young Dwarf from the Plains of Zaardun, home to the barbarian hoards. Though it's known that all Dwarves hail from the Grand Halls of Frosthammer, the untimely death of his father left Bruger alone. The mighty barbarian tribe Kuruk discovered Bruger one day and took the dwarf in as one of their own."
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-kaelas.jpg",
    title: "Kaelas Tangralas",
    description: "Kaelas Tangralas is Elf-kin from the woodland realms of the Drakkenwood. His study of nature magic has provided him extraordinary abilities which allow him to transform into certain animals at will, and call upon nature's elements to vanquish his foes. In Drakkenwood he is considered a Druid of the highest regard."
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-pots.jpg",
    title: "Pots Talos",
    description: "Pots Talos is the son of the Keeper of Truth, second heighest esteem next to the Keeper of Magic. Pots Possesses no magical talent. A shame to his father, he instead turned to the wats of the Rogue, and makes his living working for the Orgard Thieves guild."
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-laeni.jpg",
    title: "Elaenia Althorand",
    description: "Elaenia of the House Althorand, or Laeni as she likes to be called, was orphaned when she was a young girl; her parents both being the victims of a poverty related crime. Raised by her brother Atrius, Laeni became a gifted healer and a charming pub girl in Orgard. She has feelings for Pots, but she is reluctant to act on them as he is her brother's best friend."
  },
  {
    src: "https://horizonsend.com/images/horizons-end_the-great-gaias_character-wein.jpg",
    title: "Lord Wein Beranedyn",
    description: "Lord Wein Beranedyn was the Knight's Captain of Sovereign United and Champion of the Lance. Based on a premonition that came to him in the form of a dream Wein Beranedyn resigned his commission and travelled south to meet the Children of Light. It is said that Wein is the reborn soul of Arthur Greyhem, the King of Kings."
  }
]

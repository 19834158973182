import { useState, useContext, useEffect } from 'react'
import { Context } from '../Provider'
import { config } from '../config'
import server from '../utilities/server'
import { Cookies } from '../utilities/Cookies'
import { jwtDecode } from '../helpers/jwtDecode'

export default function Header() {
  const [context, dispatch] = useContext(Context)
  const [state, setState] = useState({
    games_menu_open: false,
    login_menu_open: false,
    user_menu_open: false,
    session: false,
    user: undefined,
    checked: false
  })

  const openNav = () => {
    document.getElementById('mobile-menu').style.height = '100%'
  }

  const closeNav = () => {
    document.getElementById('mobile-menu').style.height = '0%'
  }

  const handleUserLogout = event => {
    const cookie = Cookies()
    cookie.remove('refresh_token')
    cookie.remove('token')
    setState({ ...state, session: false, user_menu_open: false, login_menu_open: false })
  }

  const login = async event => {
    event.preventDefault()
    const request = {
      username: event.target.elements[0].value,
      password: event.target.elements[1].value
    }
    const response = await server.auth(config.api.user_login, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }    
    if (response.message.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      sessionStorage.setItem('store', JSON.stringify(response))
      return dispatch({ 
        type: 'modal', 
        payload: 'change-temporary-password', 
        options: config.api.user_change_temp_password 
      })
    }
    const date = new Date()
    const five_hours = new Date(date.setUTCHours(date.getHours() + 5)).toUTCString()
    const cookie = Cookies()
    cookie.set('token', response.message.AuthenticationResult.IdToken, { expires: five_hours })
    if (state.checked) {
      const thirty_days = new Date(date.setDate(date.getDate() + 30)).toUTCString()
      cookie.set('refresh_token', response.message.AuthenticationResult.RefreshToken, { expires: thirty_days })
    }
    getSession()
  }

  const getSession = async () => {
    const cookie = Cookies()
    let token = cookie.get('token')
    const refresh_token = cookie.get('refresh_token')
    if (!token && !refresh_token) {
      return 
    }
    if (token === undefined && refresh_token) {
      const response = await server.auth(config.api.refresh_token, { token: refresh_token })
      const date = new Date()
      const five_hours = new Date(date.setUTCHours(date.getHours() + 5)).toUTCString()
      const cookie = Cookies()
      cookie.set('token', response.message.AuthenticationResult.IdToken, { expires: five_hours })
      token = response.message.AuthenticationResult.idToken
    }
    const cognito = jwtDecode(token)
    const user = {
      avatar: cognito['picture'] || 'bruger-of-the-great-gaias.png',
      username: cognito['cognito:username']
    }
    setState({ ...state, user, session: true })
  }
  
  const handleDropdown = event => {
    let menu_open = undefined
    switch (event.currentTarget.firstChild.textContent) {
      case 'Sign In':
        menu_open = 'login_menu_open'
        break
      case 'Games':
        menu_open = 'games_menu_open'
        break
      default:
        menu_open = 'user_menu_open'
    }
    let other_btn = undefined
    let other_menu = undefined
    if (menu_open === 'games_menu_open') {
      other_btn = document.getElementById('login-btn') !== null ? document.getElementById('login-btn') : document.getElementById('user-btn')
      other_menu = other_btn.firstChild.textContent === 'Sign In' ? 'login_menu_open' : 'user_menu_open'
    } else {
      other_btn = document.getElementById('games-btn')
      other_menu = 'games_menu_open'
    }
    other_btn.classList.remove('dropdown-btn-hover')
    other_btn.lastChild.classList.remove('fa-caret-up')
    other_btn.lastChild.classList.add('fa-caret-down')
    other_btn.parentNode.lastChild.style.display = 'none'
    setState({ ...state, [menu_open]: !state[menu_open], [other_menu]: false })
    const btn = event.currentTarget
    const menu = event.currentTarget.parentNode.lastChild 
    if (state[menu_open]) {
      btn.classList.remove('dropdown-btn-hover')
      btn.lastChild.classList.remove('fa-caret-up')
      btn.lastChild.classList.add('fa-caret-down')
      menu.style.display = 'none'
    } else {
      btn.classList.add('dropdown-btn-hover')
      btn.lastChild.classList.remove('fa-caret-down')
      btn.lastChild.classList.add('fa-caret-up')
      menu.style.display = 'block'
    }
  }

  const closeDropdowns = () => {
    if (!state.games_menu_open && !state.login_menu_open && !state.user_menu_open) {
      return
    }
    document.addEventListener('click', event => {
      if (event.target.closest('.dropdown')) {
        return
      }
      const games_btn = document.getElementById('games-btn')
      games_btn.classList.remove('dropdown-btn-hover')
      games_btn.lastChild.classList.remove('fa-caret-up')
      games_btn.lastChild.classList.add('fa-caret-down')
      document.getElementById('games-menu').style.display = 'none'
      if (document.getElementById('user-btn') !== null) {
        const user_btn = document.getElementById('user-btn')
        user_btn.classList.remove('dropdown-btn-hover')
        user_btn.lastChild.classList.remove('fa-caret-up')
        user_btn.lastChild.classList.add('fa-caret-down')
        document.getElementById('user-menu').style.display = 'none'
      } else {
        const login_btn = document.getElementById('login-btn')
        login_btn.classList.remove('dropdown-btn-hover')
        login_btn.lastChild.classList.remove('fa-caret-up')
        login_btn.lastChild.classList.add('fa-caret-down')
        document.getElementById('login-menu').style.display = 'none'
      }
      setState({ games_menu_open: false, user_menu_open: false,  login_menu_open: false })
    })
  }

  useEffect(() => {
    if (state.games_menu_open || state.user_menu_open || state.login_menu_open) {
      closeDropdowns()
    }
    getSession()
  }, [state.session, state.games_menu_open, state.user_menu_open, state.login_menu_open])

  return (
    <header id="header">
      <div>
        <h1>
          <a href={config.routes.home} rel="home" title="Horizon's End Home">
            <img className="logo" src="https://d2rwgyv6d24c9f.cloudfront.net/horizonsend-logo-white.png" alt="Horizon's End | Video Game Design" />
          </a>
        </h1>
        <div className="mobile-menu-button" onClick={openNav}>
          <i className="fa fa-bars fa-2x"></i>
        </div>
        <div id="mobile-menu"> {/* style={{ height: state.menu_height }}> */}
          <a href="#" className="closebtn" onClick={closeNav}>&times;</a> {/* consider making the div above and this anchor an unstyled button */}
          <div className="mobile-menu-content">
            <a href={config.routes.home}>
             <span>Home</span>
            </a>
            <a href={config.routes.the_great_gaias}>The Great Gaias</a>
            <a href={config.routes.gjallarhorn}>Gjallarhorn</a>
            <a href="https://www.paypal.com/donate?business=bswahn.horizonsend%40gmail.com&currency_code=USD">Donate</a>
          </div>
        </div>
        <nav id="navigation">
          <div className="dropdown">
            <button id="games-btn" className="dropdown-btn" onClick={handleDropdown}>
              <span>Games</span>
              <i className="fas fa-caret-down"></i>
            </button>
            <div id="games-menu" className="menu">
              <div className="item">
                <a href={config.routes.the_great_gaias}>The Great Gaias</a>
              </div>
              <div className="item">
                <a href={config.routes.gjallarhorn}>Gjallarhorn</a>
              </div>
            </div>
          </div>
          {state.session 
            ? <div className="dropdown">
                <button id="user-btn" className="dropdown-btn" onClick={handleDropdown}>
                  <i className="fas fa-user-circle"></i>
                  <i className="fas fa-caret-down"></i>
                </button>
                <div id="user-menu" className="menu">
                  <div className="item-lg">
                    <div>
                      <img src={`${config.images.path}/uploads/users/avatars/${state.user.avatar}`} alt={`Avatar of ${state.user.username}`} />
                    </div>
                    <div>{state.user.username}</div>
                  </div>
                  <div className="item">
                    <a href={`/profile/${state.user.username}`}>Profile</a>
                  </div>
                  <div className="item">
                    <button onClick={handleUserLogout}>Logout</button>
                  </div>
                </div>
              </div>
            : <div className="dropdown">
                <button id="login-btn" className="dropdown-btn" onClick={handleDropdown}>
                  <span>Sign In</span>
                  <i className="fas fa-caret-down"></i>
                </button>
                <div id="login-menu" className="menu">
                  <form className="login-form-dropdown" onSubmit={login}>
                    <input type="text" placeholder="Username or Email" required />
                    <input type="password" placeholder="Password" required />
                    <button type="submit">Sign In</button>
                    <div>
                      <label htmlFor="keep-me-logged-in">
                        <input id="keep-me-logged-in" type="checkbox" />
                        <span>Keep me logged in.</span>
                      </label>
                    </div>
                  </form>
                  <div>
                    <p>Sign up to leave comments and more.</p>
                    <a href={config.routes.user_registration}>Sign Up</a>
                  </div>
                </div>
              </div>
          }
          <a className="donate-btn" 
            href="https://www.paypal.com/donate?business=bswahn.horizonsend%40gmail.com&currency_code=USD"
            rel="noreferrer"
            target="_blank"
            title="Donate Using Paypal">
            <span>Donate</span>
            <i className="fas fa-heart"></i>
          </a>
        </nav>
      </div>
    </header>
  )
}

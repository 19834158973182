import { useState, useContext } from 'react'
import { Context } from '../Provider'
import server from '../utilities/server'
import { config } from '../config'
import { v4 as uuidv4 } from 'uuid'
import { Cookies } from '../utilities/Cookies'

export default function UserLoginForm() {
  const [state, setState] = useState({ checked: false })
  const [context, dispatch] = useContext(Context)

  const login = async event => {
    event.preventDefault()
    const request = {
      username: event.target.elements[0].value,
      password: event.target.elements[1].value
    }
    const response = await server.auth(config.api.user_login, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }    
    if (response.message.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      sessionStorage.setItem('store', JSON.stringify(response))
      return dispatch({ 
        type: 'modal', 
        payload: 'change-temporary-password', 
        options: config.api.user_change_temp_password 
      })
    }
    const date = new Date()
    const five_hours = new Date(date.setUTCHours(date.getHours() + 5)).toUTCString()
    const cookie = Cookies()
    cookie.set('token', response.message.AuthenticationResult.IdToken, { expires: five_hours })
    if (state.checked) {
      const thirty_days = new Date(date.setDate(date.getDate() + 30)).toUTCString()
      cookie.set('refresh_token', response.message.AuthenticationResult.RefreshToken, { expires: thirty_days })
    }
    window.location.href = window.location.search.split('?article=')[1] || config.routes.home
  }

  const handleCheckbox = event => {
    setState({ ...state, checked: !state.checked })
  }

  return (
    <section className="form-background">
      <form className="user-form buffer-space" onSubmit={login}>
        <div>
          <h4>Log In</h4>
        </div>
        <div>
          <label htmlFor="username">Username or Email</label>
          <input id="username" type="text" placeholder="Username or Email" required />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input id="password" type="password" placeholder="Password" required />
        </div>
        <div>
          <label htmlFor="keep-me-logged-in">
           <input id="keep-me-logged-in" type="checkbox" onClick={handleCheckbox} />
           <span>Keep me logged in</span>
          </label>
        </div>
        <div>
          <button type="submit">Login</button>
        </div>
        <div>
          <a href={config.routes.user_forgot_password}>Forgot password?</a>
          <a href={config.routes.user_registration}>Register</a>
        </div>
      </form>
    </section>
  )
}

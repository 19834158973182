import Provider from './Provider'
import Modal from './Modal'
import Router from './Router'

export default function App() {
  return (
    <Provider>
      <Modal />
      <Router />
    </Provider>
  )
}


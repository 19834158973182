import { useState, useContext, useEffect } from 'react'
import { Context } from '../../Provider'
import server from '../../utilities/server'
import { config } from '../../config'
import { meta } from '../../utilities/meta'

export default function NewsArticleAdmin() {
  const [context, dispatch] = useContext(Context)
  const [state, setState] = useState({
    id: undefined,
    data: {
      image: '',
      caption: '',
      title: '',
      author: '',
      body: '',
      video: undefined
    },
    date: '',
    parsed_date: '',
    published: undefined
  })

  const loadData = async () => {
    const id = window.location.pathname.split('/')[3]
    const response = await server.get(`${config.api.news}/${id}`)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    meta({ ...response.message.data })
    setState({ ...response.message, id })
    sessionStorage.setItem('article', JSON.stringify({ id, data: response.message.data }))
  }

  const unpublishData = async event => {
    const request = { published: 'false' }
    const response = await server.put(`${config.api.news_published}/${state.id}`, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    setState({ ...state, published: false })
  }

  const publishData = async event => {
    const request = { published: 'true' }
    const response = await server.put(`${config.api.news_published}/${state.id}`, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    setState({ ...state, published: true })
  }

  const updateData = event => {
    dispatch({ type: 'modal', payload: 'update-news' })
  }

  useEffect(() => {
    const asyncWrapper = async () => {
      loadData()
    }
    asyncWrapper()
  }, [])

  return (
    <div>
      <article className="individual-article">
        <figure>
          <div className="individual-article-overlay"></div>
          <img src={`${config.images.path}/uploads/news/${state.data.image}`} alt={state.data.title} />
          <figcaption>
            <div>&nbsp; {/* state.data.caption */}</div>
          </figcaption>
        </figure>
        <div className="admin-btn-container">
          {state.published === undefined ? <></> :
          state.published
            ? <button className="unpublish-btn tooltip" onClick={unpublishData}>
                <i className="far fa-stop-circle"></i>
                <span className="tooltiptext">Unpublish</span>
              </button>
            : <button className="publish-btn tooltip" onClick={publishData}>
                <i className="fa fa-play-circle"></i>
                <span className="tooltiptext">Publish</span>
              </button>
          }
          <button className="update-btn tooltip" onClick={updateData}>
            <i className="fas fa-pencil-alt"></i>
            <span className="tooltiptext">Update</span>
          </button>
        </div>
        <header>
          <h2>{state.data.title}</h2>
          <address className="">By <a href={`/profile/${state.data.author}`} rel="author">{state.data.author}</a></address>
          &nbsp;&ndash;&nbsp; 
          <time dateTime={state.date}>{state.parsed_date}</time>
        </header>
        <div>
          {state.data.video
            ? <div className="video-container">
                <iframe style={{ width: '100%', height: '100%' }}
                  src={`https://www.youtube.com/watch?v=${state.data.video}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen></iframe>
              </div>
            : <></>
          }
          <p>{state.data.body}</p>
        </div>
      </article>
    </div>
  )
}

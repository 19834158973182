import { config } from '../config'
import server from '../utilities/server'

export default function UserConfirmForgotPasswordForm() {
  const confirmPassword = async event => {
    event.preventDefault()
    const request = {
      code: event.target.elements[0].value,
      username: event.target.elements[1].value,
      password: event.target.elements[2].value
    }
    const response = await server.auth(config.api.user_confirm_forgot_password, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    alert('Reset password successful. Please sign in with your new password.')
    window.location.href = config.routes.user_login
  }
  
  return (
    <section className="form-background">
      <form className="user-form buffer-space" onSubmit={confirmPassword}>
        <div>
          <h4>Confirm your password.</h4>
        </div>
        <div>
          <label htmlFor="code">Enter Code</label>
          <input id="code" type="text" placeholder="Enter Code" required />
        </div>
        <div>
          <label htmlFor="username">Username</label>
          <input id="username" type="text" placeholder="Username" required />
        </div>
        <div>
          <label htmlFor="password">New Password</label>
          <input id="password" type="password" placeholder="New Password" required />
        </div>
        <div>
          <button type="submit">Confirm Password</button>
        </div>
      </form>
    </section>    
  )
}
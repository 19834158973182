import { config } from '../config' 

export default function HeaderAlt() {

  return (
    <header className="header-admin">
      <a href={config.routes.home}>
        <img src="https://horizonsend.com/images/horizons-end_logo-small.png" alt="Horizon's End" />
      </a>
    </header>
  )
}

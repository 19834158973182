import { useState, useEffect } from 'react'
import server from '../utilities/server'
import { config } from '../config'
import { meta } from '../utilities/meta'
import Loading from './Loading'
import Comments from './Comments'

export default function NewsArticle() {
  const [state, setState] = useState({ 
    id: undefined,
    data: {
      image: '',
      caption: '',
      title: '',
      author: '',
      body: '',
      video: undefined
    },
    date: '',
    parsed_date: '',
    loading: false
  })

  const loadData = async () => {
    const id = window.location.pathname.split('/')[2]
    const store = JSON.parse(localStorage.getItem('store'))
    if (store !== null) {
      const article = store.find(article => article.id === id)
      meta({ ...article.data })
      return setState(article)
    }
    const response = await server.get(`${config.api.news_published}/${id}`)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    meta({ ...response.message.data })
    setState(response.message)
  }

  useEffect(() => {
    const asyncWrapper = async () => {
      loadData()
    }
    asyncWrapper()
  }, [])

  return (
    <section>
      {state.loading ? <Loading /> : <></>}
      <article className="individual-article">
        <figure>
          <div className="individual-article-overlay"></div>
          <img src={`${config.images.path}/uploads/news/${state.data.image}`} alt={state.data.title} />
          <figcaption>
            <div>&nbsp; {/* state.data.caption */}</div>
          </figcaption>
        </figure>
        <header>
          <h2>{state.data.title}</h2>
          <address className="">By <a href={`/profile/${state.data.author}`} rel="author" title="View Profile">{state.data.author}</a></address>
          &nbsp;&ndash;&nbsp; 
          <time dateTime={state.date}>{state.parsed_date}</time>
        </header>
        <div>
          {state.data.video
            ? <div style={{ 
                width: '100%', 
                height: '550px',
                marginBottom: '20px'
            }}>
                <iframe style={{ width: '100%', height: '100%' }}
                  src={state.data.video}
                  title="YouTube video player" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen></iframe>
              </div>
            : <></>
          }
          <p>{state.data.body}</p>
        </div>
        <footer>
          <div>
            <span>COMMENTS</span>
            <i className="fas fa-comment">
              <span>{/* comment count  */}</span>
            </i>
          </div>
          <div>
            <span>SHARE:</span>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)};src=sdkpreparse`} rel="noreferrer" title="Share on Facebook" target="_blank">
              <i className="fab fa-facebook"></i>
            </a>
            <a href={`https://twitter.com/share?text=${state.data.title.replace(/\W\s/g, '').replace(/\s+/g, '+')}&url=${encodeURIComponent(window.location.href)}`} rel="noreferrer" title="Share on Twitter" target="_blank">
              <i className="fab fa-twitter"></i>
            </a>
            <a href={`https://www.reddit.com/submit?url=${encodeURIComponent(window.location.href)}&title=${state.data.title.replace(/\W\s/g, '').replace(/\s+/g, '+')}`} rel="noreferrer" title="Share on Reddit" target="_blank">
              <i className="fab fa-reddit"></i>
            </a>
          </div>
        </footer>
      </article>
      <Comments />

    <div id="fb-root"></div>
<script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v10.0&appId=859039884827707&autoLogAppEvents=1" nonce="2qhOaKDR"></script>
    </section>
  )
}

import { useState, useContext } from 'react'
import { Context } from '../../Provider'
import server from '../../utilities/server'
import { config } from '../../config'
import { v4 as uuidv4 } from 'uuid'
import LoadingOverlay from '../LoadingOverlay'

export default function AdminLoginForm() {
  const [context, dispatch] = useContext(Context)
  const [state, setState] = useState({ loading: false })

  const createSession = async user  => {
    const request = {
      id: uuidv4(),
      username: user.username,
      token: user.access_token
    }
    const response = await server.auth(config.api.create_session, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    sessionStorage.setItem('SessionId', response.message.id)
  }

  const login = async event => {
    event.preventDefault()
    setState({ loading: true })
    const request = {
      username: event.target.elements[1].value,
      password: event.target.elements[2].value
    }
    const response = await server.auth(config.api.admin_login, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }    
    if (response.message.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      sessionStorage.setItem('store', JSON.stringify(response))
      return dispatch({ 
        type: 'modal', 
        payload: 'change-temporary-password', 
        options: config.api.admin_change_temp_password 
      })
    }
    await createSession({
      username: event.target.elements[1].value,
      access_token: response.message.AuthenticationResult.IdToken
    })
    sessionStorage.setItem('token', response.message.AuthenticationResult.IdToken)
    window.location.href = config.routes.admin.create_news // change this to dashboard
  }

  return (
    <section>
      {state.loading ? <LoadingOverlay /> : <></>}
      <form className="admin-login-form" onSubmit={login}>
        <fieldset>
          <legend>Sign In</legend>
          <div>
            <input type="text" placeholder="Username" required />
          </div>
          <div>
            <input type="password" placeholder="Password" required />
          </div>
        </fieldset>
        <div>
          <button type="submit">Login</button>
        </div>
        <div>
          <a href={config.routes.admin.forgot_password}>Forgot password?</a>
        </div>
      </form>
    </section>
  )
}

import { useContext } from 'react'
import { Context } from './Provider'
import NewsUpdate from './components/admin/NewsUpdate'
import ChangeTempPasswordForm from './components/ChangeTempPasswordForm'
import YouTubePlayer from './components/YouTubePlayer'
import ProfileEditForm from './components/ProfileEditForm'

export default function Modal() {
  const [context, dispatch] = useContext(Context)

  const close = () => {
    if (context.player) {
      context.player.stopVideo()
    }
    dispatch({ type: 'modal' })
  }
 
  const renderContent = () => {
    switch (context.modal.type) {
      case 'edit-profile':
        return <ProfileEditForm />
      case 'update-news':
        return <NewsUpdate />
      case 'change-temporary-password':
        return <ChangeTempPasswordForm api={context.modal.options} />
      case 'play-video':
        const content = document.getElementById('content')
        content.className = ''
        content.classList.add('youtube-modal')
        return <YouTubePlayer />
      default:
        return
    }
  }
 
  return (
    <div id="modal" className="modal" style={{
      display: context.modal.show ? 'block' : 'none'
    }}>
      <div>
        <button className="close-btn" onClick={close}>
          <i className="fa fa-times"></i>
        </button>
      </div>
      <div id="content" className="modal-content">
        {renderContent()}
      </div>
    </div>
  )
}

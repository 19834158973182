

export default function ConceptArt({ media }) {

  const onClick = event => {
    const figure = event.currentTarget
    figure.classList.toggle('show-media-item')
    figure.children[1].classList.toggle('show-caption')
  }

  return (
    <figure className="media-item" onClick={onClick}>
      <img src={media.src} alt={media.title} />
      <figcaption className="media-caption">
        <h3>{media.title}</h3>
        <p>{media.description}</p>
      </figcaption>
    </figure>
  )
}

import { config } from '../config'

export function meta(data) {

  document.title = `Horizon's End | ${data.title}`
  document.getElementsByTagName('meta')['description'].content = `${data.title}. ${data.caption}.`

  document.querySelector('meta[property="og:url"]').content = window.location.href
  document.querySelector('meta[property="og:title"]').content = `Horizon's End | ${data.title}`
  document.querySelector('meta[property="og:description"]').content = `${data.title}. ${data.caption}.`
  document.querySelector('meta[property="og:image"]').content = `${config.images.path}/uploads/news/${data.image}`

  document.getElementsByTagName('meta')['twitter:title'].content = `Horizon's End | ${data.title}`
  document.getElementsByTagName('meta')['twitter:description'].content = `${data.title}. ${data.caption}.`
  document.getElementsByTagName('meta')['twitter:image'].content = `${config.images.path}/uploads/news/${data.image}`

  document.querySelector('link[type="image/png"]').href = `${config.images.path}/uploads/news/${data.image}`

}


export const config = {
  routes: {
    home: '/',
    the_great_gaias: '/games/the-great-gaias',
    gjallarhorn: '/games/gjallarhorn',
    news_article: '/news/:id/:str',
    privacy_policy: '/privacy-policy',
    terms_and_conditions: '/terms-and-conditions',
    user_login: '/login',
    user_registration: '/register',
    user_forgot_password: '/forgot-password',
    user_recover_password: '/recover-password',
    user_profile: '/profile/:username',
    admin: {
      login: '/admin/login',
      forgot_password: '/admin/forgot-password',
      recover_password: '/admin/recover-password',
      create_news: '/admin/create-news',
      news: '/admin/news',
      news_article: '/admin/news/:id/:str'
    }
  },
  api: {

    //temp_func_api: 'https://o5t4pdwevk.execute-api.us-east-1.amazonaws.com/staging/api/v1/temp-func-api',
    
    
    upload_image: 'https://wlkprosky8.execute-api.us-east-1.amazonaws.com/staging',
    user: '',
    user_register: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/register-user',
    user_login: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/login-user',
    user_change_temp_password: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/change-temp-password-user',
    user_forgot_password: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/forgot-password',
    user_confirm_forgot_password: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/confirm-forgot-password',
    admin_login: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/login-admin',
    admin_change_temp_password: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/change-temp-password-admin',
    admin_forgot_password: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/forgot-password-admin',
    admin_confirm_forgot_password: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/confirm-forgot-password-admin',
    create_session: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/create-session',
    validate_session: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/validate-session',
    refresh_token: 'https://cagpzufd99.execute-api.us-east-1.amazonaws.com/staging/api/v1/refresh-token',
    news: 'https://o5t4pdwevk.execute-api.us-east-1.amazonaws.com/staging/api/v1/news',
    news_published: 'https://o5t4pdwevk.execute-api.us-east-1.amazonaws.com/staging/api/v1/news-published',
    news_comments: 'https://o5t4pdwevk.execute-api.us-east-1.amazonaws.com/staging/api/v1/news/id/comments',
    flag: 'https://50bschkqng.execute-api.us-east-1.amazonaws.com/staging/api/v1/flag',
    comments: 'https://o5t4pdwevk.execute-api.us-east-1.amazonaws.com/staging/api/v1/comments'
  },
  images: {
    path: 'https://d2rwgyv6d24c9f.cloudfront.net'
  }
}



export function Cookies() {
  
  const get = name => {
    if (document.cookie === '') {
      return 
    }
    const parts = document.cookie.split(';')
    const cookie = parts.reduce((acc, val) => { 
      const item_parts = val.split('=')
      return { ...acc, [item_parts[0].trim()]: item_parts[1] }
    }, {})
    return cookie[name]
  }

  const set = (name, value, expiration) => {
    //const date = new Date(new Date())
   // const expiration = new Date(date.setDate(date.getDate() + 30)).toUTCString()
    document.cookie = `${name}=${value};expires=${expiration.expires};path=/;samesite=strict;secure`
  }

  const remove = name => {
    if (document.cookie !== '' && document.cookie.split('=')[0] === name) {
      document.cookie = `${name}=;max-age=0;path=/`
    }
  }

  return { get, set, remove }
}



export default function Loading() {
  return (
    <div className="loading">
      <i className="fas fa-spinner fa-spin"></i>
      <div>Loading...</div>
    </div>
  )
}

import { useState, useEffect } from 'react'
import server from '../utilities/server'
import { formatUrl } from '../helpers/formatUrl'
import { config } from '../config'
import Loading from './Loading'

export default function News() {
  const [state, setState] = useState({
    data: [],
    range: [],
    index: undefined,
    loading: false
  })

  const loadData = async () => {
    setState({ ...state, loading: true })
    const response = await server.get(config.api.news_published)
    if (response.error !== undefined) {
      // return errorLog(response.error)
    }
    const range = response.message.slice(0, 6)
    const index = 6
    setState({ ...state, data: response.message, range, index, loading: false })
    localStorage.setItem('store', JSON.stringify(response.message))
  }

  const loadNextRange = () => {
    const index = state.index + 6
    const range = state.data.slice(state.index, index)
    setState({ ...state, range, index })
  }

  const loadPrevRange = () => {
    const index = state.index - 12
    const limit = state.index - 6
    const range = state.data.slice(index, limit)
    setState({ ...state, range, index: limit })
  }

  const renderPageNav = () => {
    const float_right = state.data[state.index - 6 + 1] === undefined ? '' : 'right'
    const float_left = state.index - 12 < 0 ? '' : 'left'
    return (
      <div className="pagination-nav">
        {state.index - 12 < 0 ? <></> 
          : <button onClick={loadPrevRange} style={{ float: float_left }}>&#10094; Previous</button>}
        {state.data[state.index] === undefined ? <></> 
          : <button onClick={loadNextRange} style={{ float: float_right }}>Next &#10095;</button>}
      </div>
    )
  }

  useEffect(() => {
    const asyncWrapper = async () => {
      loadData()
    }
    asyncWrapper()
  }, [])

  return (
    <section id="news" className="sections">
      {state.loading ? <Loading /> : <></>}
      {state.range.length ? state.range.map(news => 
        <article key={news.id} className="article">
          <figure>
            <a href={`/news/${news.id}/${news.data.parsed_title}`}>
              <img src={`${config.images.path}/uploads/news/${news.data.image}`} alt={news.data.title} />
            </a>
            <figcaption>{news.data.caption}</figcaption>
          </figure>
          <header>
            <h2>{news.data.title}</h2>
            <address className="author">
              <span>By </span> 
              <a href={`/profile/${news.data.author}`} rel="author">{news.data.author}</a>
            </address>
            &nbsp;&ndash;&nbsp;
            <time dateTime={news.date}>{news.parsed_date}</time>
            {/*
            <span style={{ marginLeft: '150px'}}>
              <span>comments: <a href={`/news/${news.id}/${news.data.parsed_title}#comments`}>5</a></span>
            </span>
            */}
          </header>
          <div>
            <p>{news.data.body.substring('0', 525)}... <a href={`/news/${news.id}/${news.data.parsed_title}`}>Read more &raquo;</a> </p>
          </div>
        </article>
      ) : <></>}
      {Object.keys(state.range).length ? renderPageNav() : <></>}
    </section>
  )
}

import { useContext } from 'react'
import { Context } from '../Provider'

export default function Gjallarhorn() {
  const [context, dispatch] = useContext(Context)

  const openModal = event => {
    const options = {
      id: 'K0JwHzIXu68' // replace with gjallarhorn id
    }
    dispatch({ type: 'modal', payload: 'play-video', options })
  }

  return (
    <main>
      <section className="topsection">
        <div>
          <div className="overlay"></div>
          <img src="https://horizonsend.com/images/gjallarhorn-logo.png" 
            srcSet="https://horizonsend.com/images/gjallarhorn-logo.png 1x, https://horizonsend.com/images/gjallarhorn-mobile-background.png 500w" 
            alt="Gjallarhorn" />
          <div className="cta">
            <div></div>
            <div className="purchase-game">
              <p>Available soon on</p>
              <a href="https://store.steampowered.com/app/1585400/Gjallarhorn" target="_blank" rel="noreferrer" title="Gjallarhorn on Steam">
                <i className="fab fa-steam-square"></i>
                <span>Steam</span>
              </a>
            </div>
          </div>
        </div>
      </section>    
    </main>
  )
}

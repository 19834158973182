import { useState, useEffect } from 'react'
import server from '../../utilities/server'
import { config } from '../../config'
import { formatUrl } from '../../helpers/formatUrl'

export default function NewsUpdate() {
  const [state, setState] = useState({
    id: undefined,
    data: {
      image: '',
      caption: '',
      title: '',
      author: '',
      body: '',
      video: undefined
    },
    base64: undefined
  })
  
  const previewFile = file => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setState({ ...state, base64: reader.result })
    }, false)
    reader.readAsDataURL(file)
  }

  const loadData = () => {
    const article = JSON.parse(sessionStorage.getItem('article'))
    setState({ id: article.id, data: article.data })
  }

  const validateImage = async event => {
    if (event.target.files[0].size > 1000000) {
      event.target.value = ''
      return alert('The file size is too large. \n Files must be 1 MB or less.')
    }
    previewFile(event.target.files[0])
  }

  const update = async event => {
    event.preventDefault()
    console.log(event.target.elements)
    const date = new Date().toISOString().split('T')[0].replaceAll('-', '')
    const image = formatUrl(event.target.elements[3].value)
    const file_type = event.target.elements[1].files[0].name.split('.')[1]
    const request = {
      file: state.base64.split(',')[1],
      image: `${image}-${date}.${file_type}`,
      caption: event.target.elements[2].value,
      title: event.target.elements[3].value,
      author: event.target.elements[4].value,
      body: event.target.elements[5].value,
      video: event.target.elements[6].value
    }
    const response = await server.put(`${config.api.news}/${state.id}`, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    window.location.href = config.routes.admin.news
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <form className="news-update-form" encType="multipart/form-data" onSubmit={update}>
      <fieldset>
        <legend>Update post</legend>
        <div className="image-upload">        
          <label htmlFor="image-upload">Upload image:</label>
          <input id="image-upload" type="file" required accept="image/png, image/jpg" onChange={validateImage} />
        </div>
        <div>
          <label htmlFor="caption">Image caption:</label>
          <input id="caption" type="text" placeholder="Image caption" minLength="10" maxLength="60" defaultValue={state.data.caption} />
        </div>
        <div>
          <label htmlFor="title">Title of post:</label>
          <input id="title" type="text" placeholder="Title of post" minLength="10" maxLength="36" defaultValue={state.data.title} />
        </div>
        <div>
          <label htmlFor="author">Author:</label>
          <input id="author" type="text" placeholder="Name of author" minLength="2" maxLength="20" defaultValue={state.data.author} />
        </div>
        <div>
          <label htmlFor="body">Body of post:</label>
          <textarea id="body" placeholder="Body of post" minLength="500" maxLength="10000" defaultValue={state.data.body}></textarea>
        </div>
        <div className="video-upload">
      {/*
          <button>Upload Video</button>
          <div className="video-upload-fields">
            <div>
              <label htmlFor="video-upload">Upload video</label>
              <input id="video-upload" type="file" />
            </div>
            <div>
              <input type="text" placeholder="Title of video" />
            </div>
            <div>
              <input type="text" placeholder="Description of video" />
            </div>
          </div>
      */}
          <input type="text" placeholder="Youtube Video URL (optional)" maxLength="400" defaultValue={state.data.video_url} />
        </div>
      </fieldset>
      <div>
        <button type="submit">Submit</button>
      </div>
    </form>
  )
}

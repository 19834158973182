import { useState, useContext, useEffect } from 'react'
import { Context } from '../Provider'
import server from '../utilities/server'
import { config } from '../config'

export default function UserRegistrationForm() {
  const [state, setState] = useState({ checked: false })
  const [context, dispatch] = useContext(Context)

  const register = async event => {
    event.preventDefault()
    if (event.target.elements[1].value !== event.target.elements[2].value) {
      return alert('Emails do not match.')
    }
    if (event.target.elements[3].value !== event.target.elements[4].value) {
      return alert('Passwords do not match.')
    }
    const request = {
      username: event.target.elements[0].value,
      email: event.target.elements[1].value,
      password: event.target.elements[3].value
    }
    const response = await server.auth(config.api.user_register, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    alert('Registration successful. Please check your email for verification before you log in.')
    window.location.href = config.routes.home
  }

  const requireRecaptcha = () => {
    const recaptcha = document.querySelector('#g-recaptcha-response')
    if (recaptcha) {
      recaptcha.setAttribute('required', 'required')
    }
  }

  useEffect(() => {
    requireRecaptcha()
  }, [])

  return (
    <section className="form-background">
      <form className="user-form" onSubmit={register}>
        <div>
          <h4>User information</h4>
        </div>
        <div>
          <label htmlFor="username"><span>*</span> Username</label>
          <input id="username" type="text" placeholder="Username" minLength="3" maxLength="50" pattern="[a-zA-Z0-9-_.]+" required />
          <p>The username you use here will be visible to the public.</p>
        </div>
        <div>
          <label htmlFor="email"><span>*</span> Email</label>
          <input id="email" type="email" placeholder="Email Address" maxLength="100" required />
          <p><b>NOTE</b>: This site requires that you verify this email address.</p>
        </div>
        <div>
          <label htmlFor="confirm_email"><span>*</span> Confirm Email Address</label>
          <input id="confirm_email" type="email" placeholder="Confirm Email Address" maxLength="100" required />
        </div>
        <div>
          <label htmlFor="password"><span>*</span> Password</label>
          <input id="password" type="password" placeholder="Password" minLength="8" maxLength="128" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&^])[A-Za-z\d@$!#%*?&^]{8,}$" required />
          <p>User passwords require at least one number, one special character, one uppercase letter, and one lowercase letter, and no fewer than 8 characters.</p>
        </div>
        <div>
          <label htmlFor="confirm_password"><span>*</span> Confirm Password</label>
          <input id="confirm_password" type="password" placeholder="Confirm Password" minLength="8" maxLength="128" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&^])[A-Za-z\d@$!#%*?&^]{8,}$" required />
        </div>
        <div>
          <h4>Human verification</h4>
          <p>Please complete the following challenge.</p>
          <div className="g-recaptcha" data-sitekey="6LdfmNMaAAAAALptHW04icPv2qBtMCEFxg9N3VEs"></div>
        </div>
        <div>
          <h4>User Agreement</h4>
          <p>By creating an account you agree to our <a href="/terms-and-conditions" target="_blank">terms and conditions</a>, <a href="/privacy-policy" target="_blank">privacy policy</a>, and <a href="" target="_blank">posting guidelines</a>.</p>
        </div>
        <div>
          <button type="submit">Register</button>
        </div>
      </form>
    </section>
  )
}

import { config } from '../config'

import News from './News'
export default function Home() {
  return (
    <main>
      <section className="topsection">
        <div>
          <img src={`${config.images.path}/gjallarhorn/gjallarhorn-title-screen.png`}
            srcSet={`${config.images.path}/gjallarhorn/gjallarhorn-title-screen.png 1x ${config.images.path}/gjallarhorn/gjallarhorn-mobile-background.png 500w`}
            alt="Gjallarhorn" />
          <div className="overlay"></div>
          <div className="cta">
            <div>Available soon on Steam</div>
            <div>Early access begins 2021</div>
          </div>
        </div>
      </section>
      <section id="news" className="sections">
        <News />
      </section>
    </main>
  )
}

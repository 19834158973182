

export default function ProfileEditForm() {
  
  const handleSubmit = async event => {
    event.preventDefault()
    
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input type="file" />
      </div>
      <div>
        <input type="text" />
      </div>
      <div>
        <button type="submit">Submit</button>
      </div>
    </form>  
  )
}
import { useContext } from 'react'
import { Context } from '../Provider'
import YouTube from 'react-youtube'

export default function YouTubePlayer() {
  const [context, dispatch] = useContext(Context)

  const onPlayerReady = event => {
    const player = event.target
    player.playVideo()
    dispatch({ type: 'youtube', payload: player })
  }

  return (
    <YouTube
      videoId={context.modal.options.id}
      onReady={onPlayerReady}
      opts={{
        height: window.innerWidth > 500 ? '720px' : '400px',
        width: window.innerWidth > 500 ? '1280px' : '100%'
      }}
    />
  )
}


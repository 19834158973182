import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { config } from './config'
import Home from './components/Home'
import TheGreatGaias from './components/TheGreatGaias'
import Gjallarhorn from './components/Gjallarhorn'
import NewsArticle from './components/NewsArticle'
import UserLoginForm from './components/UserLoginForm'
import UserRegistrationForm from './components/UserRegistrationForm'
import UserForgotPasswordForm from './components/UserForgotPasswordForm'
import UserConfirmForgotPasswordForm from './components/UserConfirmForgotPasswordForm'
import Protected from './Protected'
import AdminLoginForm from './components/admin/AdminLoginForm'
import AdminForgotPasswordForm from './components/admin/AdminForgotPasswordForm'
import AdminConfirmForgotPasswordForm from './components/admin/AdminConfirmForgotPasswordForm'
import NewsCreate from './components/admin/NewsCreate'
import NewsAdmin from './components/admin/NewsAdmin'
import NewsArticleAdmin from './components/admin/NewsArticleAdmin'
import Header from './components/Header'
import Footer from './components/Footer'
import HeaderAlt from './components/HeaderAlt'
import FooterAdmin from './components/admin/FooterAdmin'
import PrivacyPolicy from './components/legal/PrivacyPolicy'
import TermsAndConditions from './components/legal/TermsAndConditions'
import Profile from './components/Profile'
import Error404 from './components/Error404'

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={config.routes.home}>
          <Header />          
          <Home />
          <Footer />
        </Route>
        <Route exact path={config.routes.the_great_gaias}>
          <Header />
          <TheGreatGaias />
          <Footer />
        </Route>
        <Route exact path={config.routes.gjallarhorn}>
          <Header />
          <Gjallarhorn />
          <Footer />
        </Route> 
        <Route exact path={config.routes.news_article}>
          <Header />
          <NewsArticle />
          <Footer />
        </Route>
        <Route exact path={config.routes.user_login}>
          <HeaderAlt />
          <UserLoginForm />
          <Footer />
        </Route>
        <Route exact path={config.routes.user_registration}>
          <HeaderAlt />
          <UserRegistrationForm />
          <Footer />
        </Route>
        <Route exact path={config.routes.user_forgot_password}>
          <HeaderAlt />
          <UserForgotPasswordForm />
          <Footer />
        </Route>
        <Route exact path={config.routes.user_recover_password}>
          <HeaderAlt />
          <UserConfirmForgotPasswordForm />
          <Footer />
        </Route>
        <Route exact path={config.routes.admin.login}>
          <HeaderAlt />
          <AdminLoginForm />
        </Route>
        <Route exact path={config.routes.admin.forgot_password}>
          <HeaderAlt />
          <AdminForgotPasswordForm />
        </Route>
        <Route exact path={config.routes.admin.recover_password}>
          <HeaderAlt />
          <AdminConfirmForgotPasswordForm />
        </Route>
        
        <Route exact path={config.routes.user_profile}>
          <HeaderAlt />
          <Profile />
          <Footer />
        </Route>
        
        
        <Route exact path={config.routes.admin.create_news}>
          <Protected>
            <HeaderAlt />
            <NewsCreate />
          </Protected>
        </Route>
        <Route exact path={config.routes.admin.news}>
          <Protected>
            <HeaderAlt />
            <NewsAdmin />
            <FooterAdmin />
          </Protected>
        </Route>
        <Route exact path={config.routes.admin.news_article}>
          <Protected>
            <HeaderAlt />
            <NewsArticleAdmin />
            <FooterAdmin />
          </Protected>
        </Route>
        <Route exact path={config.routes.privacy_policy}>
          <HeaderAlt />
          <PrivacyPolicy />
        </Route>
        <Route exact path={config.routes.terms_and_conditions}>
          <HeaderAlt />
          <TermsAndConditions />
        </Route>
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  )
}

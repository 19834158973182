import { Cookies } from './Cookies'

function server() {
  const get = async api => {
    const response = await fetch(api)
    return response.json()
  }

  const userPost = async (api, request) => {
    const cookie = Cookies()
    const token = cookie.get('token')
    const response = await fetch(api, {
      method: 'post',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    return response.json()
  }
  
  const userPut = async (api, request) => {
    const cookie = Cookies()
    const token = cookie.get('token')
    const response = await fetch(api, {
      method: 'put',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    return response.json()
  }

  const post = async (api, request) => {
    const token = sessionStorage.getItem('token')
    const response = await fetch(api, {
      method: 'post',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    return response.json()
  }
  
  

  const put = async (api, request) => {
    const token = sessionStorage.getItem('token')
    const response = await fetch(api, {
      method: 'put',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    return response.json()
  }

  const upload = async (api, request) => {
    //const token = sessionStorage.getItem('token')
    const response = await fetch(api, {
      method: 'post',
      body: request,
      headers: {
        'Content-Type': 'multipart/form-data',
        //'Authorization': `Bearer ${token}`
      }
    })
    return response.json()
  }

  const auth = async (api, request) => {
    const response = await fetch(api, {
      method: 'post',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.json()
  }

  const validateAuth = async (api, request) => {
    const token = sessionStorage.getItem('token')
    const response = await fetch(api, {
      method: 'post',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    return response.json()
  }

  return { get, userPost, userPut, post, put, upload, auth, validateAuth }
}

export default server()
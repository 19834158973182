import { useContext } from 'react'
import { Context } from '../Provider'
import { config } from '../config'
import ConceptArt from './games/ConceptArt'
import { tgg_data } from './games/tgg_data'

export default function TheGreatGaias() {
  const [context, dispatch] = useContext(Context)

  const openModal = event => {
    dispatch({ 
      type: 'modal', 
      payload: 'play-video', 
      options: { id: 'K0JwHzIXu68' } 
    })
  }

  return (
    <main>
      <section className="topsection">
        <div>
          <div className="overlay"></div>
            <img src="https://horizonsend.com/images/the-great-gaias-title-screen.png" 
                 srcSet="https://horizonsend.com/images/the-great-gaias-title-screen.png 1x, https://horizonsend.com/images/the-great-gaias-mobile-background.png 500w" 
                 alt="The Great Gaias" />
            <div className="cta">
              <div className="play-button">
                <i className="far fa-play-circle" onClick={openModal}></i>
              </div>
              <div className="purchase-game">
                <p>Available on</p>
                <a href="https://store.steampowered.com/app/818610/The_Great_Gaias/" target="_blank" rel="noreferrer" title="The Great Gaias available on Steam">
                  <i className="fab fa-steam-square"></i>
                  <span>Steam</span>
                </a>
              </div>
            </div>
          </div>
      </section>
      <section className="sections">
        <div id="external-media">
          <div>
            <iframe className="steam-widget" src="https://store.steampowered.com/widget/818610/"></iframe>
            <div>
              <a href="https://store.steampowered.com/app/818610/The_Great_Gaias/" target="_blank" rel="noreferrer" title="Download The Great Gaias Demo on Steam">
                <img src="https://horizonsend.com/images/download-demo.png" alt="Download The Great Gaias Demo" />
              </a>
            </div>
          </div>
          <div>
            <iframe className="bandcamp-widget" src="https://bandcamp.com/EmbeddedPlayer/album=3182079143/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/" seamless>
              <a href="https://horizonsend.bandcamp.com/album/the-great-gaias-original-soundtrack">The Great Gaias - Original Soundtrack by Bryan Davis &amp; Aaron Krogh</a>
            </iframe>
          </div>
        </div>
      </section>
      <section id="reviews" className="reviews-section">
        <div>
          <div></div>
          <div>
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/1026.png`} alt="review-1026" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/1048.png`} alt="review-1048" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/1099.png`} alt="review-1099" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/1149.png`} alt="review-1149" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/1150.png`} alt="review-1150" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/1152.png`} alt="review-1152" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/1211.png`} alt="review-1211" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/687.png`} alt="review-687" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/776.png`} alt="review-776" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/889.png`} alt="review-889" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/937.png`} alt="review-937" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/959.png`} alt="review-959" />
            <img className="review" src={`${config.images.path}/the-great-gaias/reviews/967.png`} alt="review-967" />
          </div>
        </div>
      </section>
      <section id="media" className="sections">
        {tgg_data.map((item, index) => <ConceptArt key={index} media={item} />)}
      </section>
    </main>
  )
}

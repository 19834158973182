import { useState, useEffect } from 'react'
import server from '../../utilities/server'
import { formatUrl } from '../../helpers/formatUrl'
import { config } from '../../config'
import Loading from '../Loading'

import { news_data } from './news_data'
import { v4 as uuidv4 } from 'uuid'

export default function NewsAdmin() {
  const [state, setState] = useState({
    data: [],
    loading: false
  })

  const loadData = async () => {
    setState({ ...state, loading: true })
    const response = await server.get(config.api.news)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    setState({ ...state, data: response.message, loading: false })
  }

/*
  const crazyFunc = async () => {
    const formatted_data = news_data.map(news => {
      const { datetime, published, ...data } = news
      return { data: JSON.stringify(data), datetime, published }
    })

    console.log('formatted data: ', formatted_data)
    
    for (const item of formatted_data) {
      
      await new Promise(resolve => setTimeout(resolve, 2000))

      const response = await fetch(config.api.temp_func_api, {
        method: 'post',
        body: JSON.stringify(item),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      console.log(`DATA ITEM`, data)
    }
    console.log('fin.')
  }
*/

  useEffect(() => {
    const asyncWrapper = async () => {
      loadData()
    }
    asyncWrapper()
  }, [])

  return (
    <section id="news" className="sections">
      {state.loading ? <Loading /> : <></>}
      {state.data.length ? state.data.map(news => 
        <article key={news.id} className="article">
          <figure>
            <a href={`/admin/news/${news.id}/${news.data.parsed_title}`}>
              <img src={`${config.images.path}/uploads/news/${news.data.image}`} alt={news.data.title} />
            </a>
            <figcaption>{news.data.caption}</figcaption>
          </figure>
          <header>
            <h2>{news.data.title}</h2>
            <address className="author">By <a href={`/profile/${news.data.author}`} rel="author">{news.data.author}</a></address> {/* TODO: format author link  */}
            &nbsp;&ndash;&nbsp;
            <time dateTime={news.date.split('T')[0]}>{news.parsed_date}</time>
          </header>
          <div>
            <p>{news.data.body.substring('0', 525)}... <a href={`/admin/news/${news.id}/${news.data.parsed_title}`}>Read more &raquo;</a> </p>
          </div>
        </article>
      ) : <></>}
    </section>
  )
}

import { useState } from 'react'
import server from '../../utilities/server'
import { config } from '../../config'
import { formatUrl } from '../../helpers/formatUrl'
import LoadingOverlay from '../LoadingOverlay'

export default function NewsCreate() {
  const [state, setState] = useState({ 
    loading: false,
    base64: undefined
  })
  
  const previewFile = file => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setState({ ...state, base64: reader.result })
    }, false)
    reader.readAsDataURL(file)
  }
  
  const validateImage = event => {
    if (event.target.files[0].size > 1000000) {
      event.target.value = ''
      return alert('The file size is too large. \n Files must be 1 MB or less.')
    }
    previewFile(event.target.files[0])
  }

  const create = async event => {
    event.preventDefault()
    setState({ loading: true })
    const date = new Date().toISOString().split('T')[0].replaceAll('-', '')
    const image = formatUrl(event.target.elements[3].value)
    const file_type = event.target.elements[1].files[0].name.split('.')[1]
    const request = {
      file: state.base64.split(',')[1],
      image: `${image}-${date}.${file_type}`,
      caption: event.target.elements[2].value,
      title: event.target.elements[3].value,
      author: event.target.elements[4].value,
      body: event.target.elements[5].value,
      video: event.target.elements[6].value
    }
    const response = await server.post(config.api.news, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    window.location.href = config.routes.admin.news
  }

  return (
    <section className="container">
      {state.loading ? <LoadingOverlay /> : <></>}
      <div className="info">
        <p style={{textAlign:'center'}}>You will be able to review your submission before it is made public. After submitting click on your newly created news item to view and edit. To make public click the publish button. The publish button is available on the edit page.</p>
      </div>
      
      <form className="news-create-form" onSubmit={create}>
        <fieldset>
          <legend>Create a post</legend>
          <div className="image-upload">        
            <label htmlFor="image-upload">Upload image:</label>
            <input id="image-upload" type="file" required accept="image/png, image/jpeg, image/gif" onChange={validateImage} />
          </div>
          {state.base64 
            ? <div id="image-preview" style={{width:'100%', marginBottom:'20px'}}>
                <img src={state.base64} alt="Image preview" style={{width:'100%'}} />
              </div> 
            : <></>}
          <div>
            <input type="text" placeholder="Image caption" required minLength="10" maxLength="55" />
          </div>
          <div>
            <input type="text" placeholder="Title of post" required minLength="10" maxLength="36" />
          </div>
          <div>
            <input type="text" placeholder="Name of author" required minLength="2" maxLength="20" />
          </div>
          <div>
            <textarea placeholder="Body of post" required minLength="500" maxLength="10000"></textarea>
          </div>
          <div className="video-upload">
          {/*
          <button>Upload Video</button>
          <div className="video-upload-fields">
            <div>
              <label htmlFor="video-upload">Upload video</label>
              <input id="video-upload" type="file" onChange={uploadVideo} />
            </div>
            <div>
              <input type="text" placeholder="Title of video" />
            </div>
            <div>
              <textarea placeholder="Description of video"></textarea>
            </div>
          </div>
          */}
            <input type="text" placeholder="Youtube Video ID (optional)" maxLength="400" />
          </div>
        </fieldset>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </section>
  )
}

import { useState, useContext, useEffect } from 'react'
import { Context } from '../Provider'
import { config } from '../config'
import server from '../utilities/server'
import { v4 as uuidv4 } from 'uuid'
import { jwtDecode } from '../helpers/jwtDecode'
import { Cookies } from '../utilities/Cookies'
import Loading from './Loading'

export default function Comments() {
  const [context, dispatch] = useContext(Context)
  const [state, setState] = useState({
    id: undefined,
    user: undefined,
    data: [],
    session: false,
    session_expired: false,
    loading: false,
    field_height: 100,
    counter: 0
  })

  const loadData = async () => {
    setState({ ...state, loading: true })
    const id = window.location.pathname.split('/')[2]
    const api = config.api.news_comments.replace('id', id)
    const response = await server.get(api)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    const cookie = Cookies()
    let token = cookie.get('token')
    const refresh_token = cookie.get('refresh_token')
    if (!token && !refresh_token) {
      return setState({ ...state, data: response.message, loading: false })
    }
    const cognito = jwtDecode(token)
    const user = { 
      id: cognito['sub'],
      username: cognito['cognito:username'],
      avatar: cognito['picture'] || 'bruger-of-the-great-gaias.png'
    }
    
    console.log('data: ', response.message)
    
    setState({ ...state, id, user, data: response.message, session: true, loading: false })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const cookie = Cookies()
    const token = cookie.get('token')
    const request = {
      id: uuidv4(),
      user: state.user,
      post_id: state.id,
      data: event.target.elements[0].value
    }
    if (state.session_expired) {
      const refresh_token = cookie.get('refresh_token')
      await server.auth(config.api.refresh_token, { token: refresh_token })
    }
    const api = config.api.news_comments.replace('id', state.id)
    const response = await server.userPost(api, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    document.getElementById('comment-textarea').value = ''
    loadData()
  }

  const handleTextareaExpansion = event => {
    const count = event.target.value.split('\n').length - 1
    if (state.counter <= 3) {
      return setState({  ...state, field_height: 100, counter: count })
    }
    if (count > state.counter) {
      return setState({ ...state, field_height: state.field_height + 16, counter: count })
    } 
    if (count < state.counter) {
      setState({ ...state, field_height: state.field_height - 16, counter: count })
    }
  }

  const handleFlagComment = async event => {
    const class_list = event.currentTarget.classList
    
    console.log('button classList: ', class_list)
    
    const set_flag = [...class_list].includes('flag-set')
    
    console.log('set_flag: ', set_flag)
    
    if (set_flag) {
      class_list.remove('flag-set')
      class_list.add('flag-unset')
    } else {
      class_list.remove('flag-unset')
      class_list.add('flag-set')
    }
    const flag = event.currentTarget.getAttribute('data-type')
    if (!set_flag && !window.confirm(`Are you sure you wish to ${flag} this comment?`)) {
      return
    }
    const id = event.currentTarget.closest('article').id
    const request = {
      action: !set_flag,
      type: flag,
      user: { id: state.user.id, username: state.user.username },
      reason: ''
    }
    
    console.log('request: ', request)
    
    const response = await server.userPut(`${config.api.flag}/${id}`, request)
    if (response.error !== undefined) {
      return alert(response.error.message)
    }
    
    if (flag === 'delete') {
      loadData()
    }
    // btn state:
    // comment.flag.type
    // need to maintain some state about the flag buttons
    // set in loadData() the original state
    // then update it here
    //setState({ ...state,  [flag]: !state[flag] })
    dispatch({ type: 'modal', payload: 'flag-comment', options: { type: flag } })
  }
  
  const handleEditComment = event => {
    dispatch({ type: 'modal', payload: 'edit-comment' })
  }
  
  const handleQuoteComment = (event, nested_blockquote = undefined, quote_string = '') => {
    const element = nested_blockquote || event.target.closest('article')
    const username = element.querySelector('.username').textContent
    const comment_id = element.id
    const comment_text = element.querySelector('.comment-text')
    const quote_text = blockquote ? element.querySelector('.quote-text') : undefined
    const container = quote_text ? quote_text : comment_text
    let quote = quote_string
    let blockquote = undefined
    for (let node of container.childNodes) {
      if (node.nodeName === 'BLOCKQUOTE') {
        blockquote = node
        continue
      }
      if (node.nodeName === 'IMG') {
        const parts = node.src.split('/')
        const emoji = parts[parts.length - 1].split('.')[0]
        quote += `[:${emoji}:]`
        continue
      }
      if (node.nodeName === 'A') {
        const url = node.href
        quote += `[link]${url}[/link]`
        continue
      }
      quote += node.textContent
    }
    const value = `[quote=${username};comment=${comment_id}]${quote}[/quote]`
    if (blockquote) {
      console.log('has blockquote (nested)')
      return handleQuoteComment(event, blockquote, value)
    }
    
    // This is broken sizing shit:
    // NOTE: have to count line breaks not characters!
    const quote_height = quote_string.length
    const additional_height = quote_height < 400 ? 100 : (quote_height / 60) * 16   
    setState({ ...state, field_height: additional_height })

    const textarea = document.getElementById('comment-textarea')
    textarea.value = `${value}\n`
    textarea.scrollIntoView({ behavior: 'smooth', block: 'center' })
    setTimeout(() => textarea.focus(), 1000)
  }
  
  const showEmoji = async event => {
    event.preventDefault()
    const element = document.querySelector('.emoji-container')
    const emojis = document.querySelectorAll('.emoji') 
    if (element.clientWidth === 0) {
      element.style.width = '310px'
      element.style.visibility = 'visible'
      for (let emoji of emojis) {
        emoji.style.visibility = 'visible'
        await new Promise(resolve => setTimeout(resolve, 100))
      }
    } else {
      element.style.width = '0px'
      element.style.visibility = 'hidden'
      emojis.forEach(emoji => emoji.style.visibility = 'hidden')
    }
  }

  const useEmoji = event => {
    const parts = event.target.src.split('/')
    const emoji = parts[parts.length - 1].split('.')[0]
    document.getElementById('comment-textarea').value += `[:${emoji}:]`
  }

  const handleOpenCreateLink = event => {
    event.preventDefault()
    document.querySelector('.create-link').style.display = 'block'
  }

  const handleCloseCreateLink = event => {
    event.preventDefault()
    event.currentTarget.parentNode.firstChild.style.border = '1px solid #666'
    document.querySelector('.create-link').style.display = 'none'
  }

  const validateUrl = url => {
    // feel free to expand on this function
    try {
      new URL(url)
      return true
    } catch (error) {
      return false
    }
  }

  const handleCreateLink = event => {
    event.preventDefault()
    const input = event.target.parentNode.firstChild
    if (input.value === '') {
      event.currentTarget.parentNode.firstChild.style.border = '2px solid red'
      return
    }
    if (!validateUrl(input.value)) {
      return
    }
    event.currentTarget.parentNode.firstChild.style.border = '1px solid #666'
    document.getElementById('comment-textarea').value += `[link]${input.value}[/link]`
    document.querySelector('.create-link').style.display = 'none'
    input.value = ''
  }

  const handleCreateLinkKeyPress = event => {
    event.preventDefault()
    if (event.keyCode === 13) {
      document.querySelector('.create-link').lastChild.click()
    }
  }
  
  const handleExternalLinkWarning = event => {
    const url = new URL(event.target.href)
    if (url.hostname === 'horizonsend.com') {
      return 
    }
    if (!window.confirm('You are about to open an external website. Continue?')) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    loadData()
  }, [])
  
  const renderFlagButton = comment => {
    const flag = comment.flag && comment.flag.type === 'flag' ? 'flag-set' : 'flag-unset'
    return (
      <button className={`tooltip-top ${flag}`} data-type="flag" onClick={handleFlagComment}>
        <i className="fas fa-flag"></i>
        <span className="tooltip-top-text">Flag this comment.</span>
      </button>  
    )
  }

  const renderEmoji = (data, accumulator = []) => {
    const regex = /\[:(.*)\:]/
    const has_emoji = regex.test(data)
    if (!has_emoji && !accumulator.length) {
      return data
    }
    if(!has_emoji) {
      return [ ...accumulator, data ]
    }
    const emoji = {
      "kaelas-bear": "kaelas-bear.png",
      "kaelas-panther": "kaelas-panther.png",
      "observer": "observer.png",
      "vathen": "vathen.gif"
    }
    const size = {
      "kaelas-bear": "16px",
      "kaelas-panther": "30px",
      "observer": "30px",
      "vathen": "22px"
    }
    const arr = [
      data.substring(0, data.indexOf(':]') + 2), 
      data.substring(data.indexOf(':]') + 2)
    ]
    const key = arr[0].substring(
      arr[0].indexOf('[:') + 2, 
      arr[0].indexOf(':]')
    )
    if (emoji[key] === undefined) {
      return data
    }
    const acc = [
      ...accumulator,
      arr[0].substring(0, arr[0].indexOf('[:')),
      <img src={`${config.images.path}/emoji/${emoji[key]}`} alt={key} style={{width: size[key]}} />
    ]
    return renderEmoji(arr[1], acc)
  }
  
  const renderLink = (data, accumulator = []) => {
    const regex = /\[link]/
    if (Array.isArray(data)) {
      for (let acc = [], i = 0; i <= data.length - 1; i++) {
        if (typeof data[i] === 'string' && regex.test(data[i])) {
          return renderLink(data[i], acc)   
        }
        acc = [ ...acc, data[i] ]
        if (i === data.length - 1) {
          return acc
        }
      }
    }
    const has_link = regex.test(data)
    if (!has_link && !accumulator.length) {
      return data
    }
    if(!has_link) {
      return [ ...accumulator, data ]
    }
    const arr = [
      data.substring(0, data.indexOf('[/link]') + 7), 
      data.substring(data.indexOf('[/link]') + 7)
    ]
    const url = arr[0].split('[link]')[1].split('[/link]')[0]
    const acc = [
      ...accumulator,
      arr[0].substring(0, arr[0].indexOf('[link]')),
      <a href={url} rel="noreferrer noopener" target="_blank" onClick={handleExternalLinkWarning}>{url}</a>
    ]
    return renderLink(arr[1], acc)
  }

  const renderBlockquotes = (data, accumulator = []) => {
    const regex = /\[quote=/
    if (Array.isArray(data)) {
      for (let acc = [], i = 0; i <= data.length - 1; i++) {
        if (typeof data[i] === 'string' && regex.test(data[i])) {
          const [ head, ...rest ] = data
          const value = i === 0 ? rest : acc
          return renderBlockquotes(data[i], value)   
        }
        acc = [ ...acc, data[i] ]
        if (i === data.length - 1) {
          return acc
        }
      }
    }
    const has_quote = regex.test(data)
    if (!has_quote && !accumulator.length) {
      return data
    }
   /*
    if (!has_quote) {
      console.log('inside !has_quote')
      return [ ...accumulator, data ]
    }
    */
    const segment = data.split('[quote=')
    const username = segment[1].split(';')[0]
    const comment_id = segment[1].split('comment=')[1].split(']')[0]
    const delimiter = `[quote=${username};comment=${comment_id}]`
    
    console.log('delimiter: ', delimiter)
    
    //const parts = data.split(delimiter)[1].split('[/quote]')
    
    console.log('data: ', data)
    
    //const quote = data.substring(delimiter.length, data.lastIndexOf('[/quote]'))
    //const comment = parts[parts.length - 1]
    
    const [ head, ...rest ] = accumulator
    
    
    const parts = accumulator[accumulator.length - 1].split('[/quote]\n')
    const quote = parts[0].split('[/quote]')[0]
    const comment = parts[1].length ? parts[1] : undefined
    
    console.log('comment: ', comment) // see if labels work, look for link/emoji
    
    const new_accumulator = [head, quote]
    
    // this works so far without nested quotes (untested)
    
    const multiple_quotes = regex.test(quote)
    
    console.log('here')

    console.log('quote: ', quote)
    console.log('accumulator: ', accumulator)
    
    return (
      <div>
        <blockquote id={comment_id}>
          <div>Originally posted by <a className="username" href={`/profile/${username}`}>{username}</a></div>
          <div className="quote-text">{multiple_quotes ? renderBlockquotes(renderLink(renderEmoji(accumulator))) : renderLink(renderEmoji(new_accumulator))}</div>
        </blockquote>
        <div className="comment-text">{comment}</div>
      </div>
    )
  }

  return (
    <section className="comments-section">
      {state.data.length ? <h3>{state.data.length} Comments</h3> : <></>}
      {state.loading ? <Loading /> : <div>
      {state.data.length ? state.data.map(comment =>
        <article id={comment.id} key={comment.id} className="comment">
          <figure>
            <img src={`${config.images.path}/uploads/users/avatars/${comment.user.avatar}`} alt={`Avatar of ${comment.user.username}`} />
          </figure>
          <div>
            <header>
              <a className="username" href={`/profile/${comment.user.username}`}>{comment.user.username}</a>
              <span>{comment.date_created}</span>
            </header>
            <div className="text comment-text">
              {renderBlockquotes(renderLink(renderEmoji(comment.data)))}
            </div>
            <footer>
              {comment.date_updated.substring(0, 2) !== comment.date_created.substring(0, 2) 
                ? <span>Last edited by <a href="">{comment.user.username}</a> on {comment.date_updated}</span> 
                : <></>
              }
              {state.session 
                ? <div>
                    <button className="tooltip-top" onClick={(event) => handleQuoteComment(event)}>
                      <i className="fas fa-quote-left"></i>
                      <span className="tooltip-top-text">Quote this comment.</span>
                    </button>
                    {renderFlagButton(comment)}
                  </div>
                : <></>
              }
              {state.user !== undefined && state.user.id === comment.user.id
                ? <div>
                    <button className="tooltip-top" data-type="delete" onClick={handleFlagComment}>
                      <i className="fas fa-trash-alt"></i>
                      <span className="tooltip-top-text">Delete this comment.</span>
                    </button>
                    <button className="tooltip-top" onClick={handleEditComment}>
                      <i className="fas fa-pencil-alt"></i>
                      <span className="tooltip-top-text">Edit this comment.</span>
                    </button>
                  </div>
                : <></>
              }
            </footer>
          </div>
        </article>
      ) : <></> }
      </div>}
      {state.session
        ? <aside>
            <form className="comments-form" onSubmit={handleSubmit}>
              <div>
                <textarea id="comment-textarea" placeholder="Leave a comment." onChange={handleTextareaExpansion} style={{height: `${state.field_height}px`}} maxLength="10000" required></textarea>
              </div>
              <div>
                <div className="emoji-container">
                  <img className="emoji" src={`${config.images.path}/emoji/observer.png`} alt="Observer" onClick={useEmoji} />
                  <img className="emoji" src={`${config.images.path}/emoji/kaelas-panther.png`} alt="Kaelas Panther Form" onClick={useEmoji} />
                  <img className="emoji" src={`${config.images.path}/emoji/vathen.gif`} alt="Vathen" onClick={useEmoji} style={{width: '29px'}} />
                  <img className="emoji" src={`${config.images.path}/emoji/kaelas-bear.png`} alt="Kaelas Bear Form" onClick={useEmoji} style={{width: '24px'}} />
                </div>
                <button className="emoji-btn" onClick={showEmoji}>
                  <i className="far fa-smile"></i>
                </button>
                <div className="create-link" style={{width: '450px'}}>
                  <input type="url" placeholder="https://example.com" />
                  <button onClick={handleCloseCreateLink}>Cancel</button>
                  <button onClick={handleCreateLink}>Create</button>
                </div>
                <button className="create-link-btn" onClick={handleOpenCreateLink}>
                  <i className="fas fa-link"></i>
                </button>
                <button className="comment-submit-btn" type="submit">Post Comment</button>
              </div>
            </form>
          </aside>
        : <p>You must <a href={`${config.routes.user_login}?article=${window.location.pathname}`}>login or register</a> to comment.</p>
      }
    </section>
  )
}
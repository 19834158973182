import { useState, useContext, useEffect } from 'react'
import { Context } from '../Provider'

export default function Profile() {
  const [context, dispatch] = useContext(Context)
  const [state, setState] = useState({
    user: undefined,
    comments: []
  })
  
  const loadData = async () => {
    // get user from cookie
    // get comments with server requests
  }
  
  const editProfile = event => {
    dispatch({ type: 'modal', payload: 'edit-profile' })
  }
  
  useEffect(() => {
    loadData()
  }, [])
  
  return (
    <div>
      <div>
        <img src="" alt="" />
        <h2></h2>
        {/* username, name, location, bio, rank by comment count */}
        <button onClick={editProfile}>
          <i className="fas fa-user-edit"></i>
          <span>Edit Profile</span>
        </button>
      </div>
      <div>
        <h2>Recent Activity</h2>
        {/* list of recent comments */}
      </div>
    </div>
  )
}
import { config } from '../config'

export default function Error404() {
  return (
    <div className="error-404">
      <a href={config.routes.home}>
        <img src={`${config.images.path}/horizonsend-logo-black.png`} alt="Horizon's End'" title="Horizon's End Home" />
      </a>
      <h2>404 Not found.</h2>
      <p>The page you are looking for was not found.</p>
    </div>
  )
}
